<template>
  <div>
    <validation-observer ref="simpleRules">
      <!-- <this-header /> -->
      <div class="anan-set-nav-conttainer mt-150">
        <div class="row">
          <div class="col-lg-2 col-md-2 col-sm-12">
            <side-bar class="d-none d-md-block" />
          </div>

          <div class="col-md-10 col-lg-10 col-sm-12">
            <b-overlay
              :show="showOver"
              variant="transparent"
              opacity="0.99"
              blur="5px"
              rounded="sm"
            >
              <template #overlay>
                <div class="text-center">
                  <p id="cancel-label">
                    กรุณารอสักครู่...
                  </p>
                </div>
              </template>
            <div>
              <b-button
                class="btn btn-gradient-primary mb-1 mr-50"
                @click="$router.push({ name: 'customer-refill' })"
              >
                <i class="fas fa-arrow-left mr-25" /> {{ $t("returnToList") }}
              </b-button>
              <b-button
                class="btn btn-gradient-success mb-1 ml-25 mr-50"
                :disabled="isDisabled"
                @click="Submit()"
              >
                <i class="far fa-save mr-50" /> {{ $t("saveData") }}
              </b-button>

              <b-row class="match-height">
                <b-col md="6">
                  <div class="card">
                    <div class="header-cardx p-2">
                      <h3 class="mb-50">
                        {{ $t("accountToTransfer") }}
                      </h3>
                    </div>

                    <div>
                      <b-row class="p-2">
                        <div
                          v-for="(item, index) in Bank"
                          :key="index"
                          no-body
                          class="text-left w-100"
                        >
                          <b-form-group class="bg-custom">
                            <b-form-radio
                              v-model="selected"
                              name="some-radio9"
                              :value="item"
                              class="custom-control mb-50 mt-50"
                            >
                              <div class="radio-content mt-50">
                                <img
                                  :src="`/bankIcon/${item.path_photo}`"
                                  :alt="item"
                                  class="radio-image ml-2"
                                >
                                <div class="mt-50">
                                  <ul class="hide-bullet">
                                    <li>{{ item.bankname }}</li>
                                    <li>{{ item.accName }}</li>
                                    <li>{{ item.accNumber }}</li>
                                    <li>{{ item.branch }}</li>
                                  </ul>
                                </div>
                              </div>
                            </b-form-radio>
                          </b-form-group>
                        </div>
                      </b-row>
                    </div>
                  </div>
                </b-col>

                <b-col md="6">
                  <div class="card">
                    <div class="header-cardx p-2">
                      <h3 class="mb-50">
                        {{ $t("details") }}
                      </h3>
                    </div>

                    <div class="p-2">
                      <b-row>
                        <b-col md="12">
                          <b-form-group
                            label-for="basic-addon3"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                            class="mb-50"
                          >
                            <template v-slot:label>
                              <span class="text-danger">*</span>
                              {{ $t("transferFromAccount") }}
                            </template>
                            <span v-if="itemsbank">
                              {{ $t("key-55") }}: {{ itemsbank.name_bank }}
                              <br>
                              {{ $t("accountName") }}:
                              {{ itemsbank.name_account }} <br>
                              {{ $t("accountNumber") }}:
                              {{ itemsbank.number_account }} <br>
                              {{ $t("branch") }}:
                              {{ itemsbank.branch ? itemsbank.branch : "-" }}
                              <br>
                              <!-- <div
                                class="text-warning"
                              > -->
                              <br>
                              <button
                                class="btn btn-gradient-warning"
                                @click="$bvModal.show('modal-bank-list')"
                              >
                                <span class="text-dark">{{ $t("edit") }}</span>
                              </button>
                              <!-- </div> -->
                            </span>
                            <button
                              v-else
                              class="btn-add-bank"
                              @click="$bvModal.show('modal-bank-list')"
                            >
                              <i class="far fa-plus mr-25" />
                              {{ $t("selectBankAccount") }}
                            </button>
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-form-group
                            label-for="basic-addon3"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                            class="mb-50"
                          >
                            <template v-slot:label>
                              <span class="text-danger">*</span>
                              {{ $t("transferAmount") }}
                            </template>
                            <validation-provider
                              #default="{ errors }"
                              name="price"
                              rules="required"
                            >
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                  id="basic-addon3"
                                  v-model="price"
                                  type="number"
                                  placeholder="0"
                                  :state="errors.length > 0 ? false : null"
                                />
                                <b-input-group-append is-text>
                                  ฿
                                </b-input-group-append>
                              </b-input-group>
                              <small class="text-danger">{{
                                errors[0] ? $t('requiredField') : ""
                              }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="basic-addon3"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                            class="mb-50"
                          >
                            <template v-slot:label>
                              <span class="text-danger">*</span>
                              {{ $t("transactionDate") }}
                            </template>
                            <flat-pickr
                              v-model="dateNtim"
                              class="form-control"
                              :placeholder="$t('selectDateTime')"
                              :config="flatpickrConfig"
                            />
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-file1"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                            class="mb-50"
                          >
                            <template v-slot:label>
                              <span class="text-danger">*</span>
                              {{ $t("transferEvidence") }}
                            </template>
                            <b-form-file
                              id="h-file1"
                              v-model="file_cus"
                              accept=".jpg, .png, .jpeg"
                              :placeholder="$t('attachFile')"
                              drop-placeholder="Drop file here..."
                              @change="fileImage"
                            />
                            <div class="mt-2 d-flex justify-content-left">
                              <span
                                v-if="imageOr"
                                class="preview-image-containerv2 mr-2 d-flex justify-content"
                              >
                                <!-- {{ imageOr }} -->
                                <img
                                  :src="imageOr"
                                  alt="Preview"
                                  class=""
                                  @click="showPicture(imageOr)"
                                >
                                <div class="preview-image-overlays p-0">
                                  <i
                                    class="fas fa-times-circle cursor-pointer"
                                    @click="deleteImageorder()"
                                  />
                                </div>
                              </span>
                            </div>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-remark"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                            class="mb-50"
                          >
                            <template v-slot:label>
                              {{ $t("remarks") }}
                            </template>
                            <b-form-textarea
                              id="h-remark"
                              v-model="remark"
                              type="text"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-modal
                id="modal-bank-list"
                :title="$t('selectBankAccount')"
                size="lg"
                :ok-title="$t('key-124')"
                :cancel-title="$t('cancel')"
                @ok="addBank()"
              >
                <button
                  class="btn btn-outline-primary btn-sm"
                  @click="EditType()"
                >
                  <i class="fal fa-plus" /> {{ $t("addInfo") }}
                </button>
                <div class="pl-2 pr-2">
                  <b-table
                    responsive="sm"
                    :fields="fieldsbank"
                    :items="itemBank"
                    show-empty
                  >
                    <template #empty="">
                      <b-table-empty-slot>
                        <div class="text-center my-2">
                          <img
                            src="@/assets/images/anan-img/svg/doc_empty.svg"
                            alt="empty"
                            height="60px"
                          >
                          <p class="text-muted">
                            No Data
                          </p>
                        </div>
                      </b-table-empty-slot>
                    </template>

                    <!-- <template #cell(rate)="data">
                      {{ data.item.rate_payment }} -->
                    <!-- <span class="text-success">
                            {{ data.value }} min_price
                          </span> -->
                    <!-- </template> -->

                    <template #cell(set)="data">
                      <b-form-checkbox
                        v-if="data.item.status !== 1"
                        v-model="checkeditem"
                        :value="data.item"
                        :unchecked-value="null"
                      />
                    </template>
                    <template #cell(branch)="data">
                      {{ data.item.branch ? data.item.branch : "-" }}
                    </template>

                    <template #cell(actions)="data">
                      <b-badge
                        v-if="data.item.status === 1"
                        variant="warning"
                      >
                        <span class="text-dark">{{ $t('pendingReview') }} Account </span>
                      </b-badge>
                      <b-button
                        v-if="data.item.status === 0 || data.item.status === 3"
                        size="sm"
                        pill
                        variant="gradient-success"
                        style="margin-left: 5px; padding: 3px 6px 3px 6px;width:80px;"
                        @click="verifyBank(data.item)"
                      >
                        {{ $t('key-203') }}
                      </b-button>
                      <br v-if="data.item.status === 0 || data.item.status === 3">
                      <feather-icon
                        v-if="data.item.status === 0 || data.item.status === 3"
                        icon="Edit3Icon"
                        class="mr-1 text-primary cursor-pointer"
                        @click="EditType(data.item)"
                      />
                      <feather-icon
                        icon="TrashIcon"
                        class="text-danger cursor-pointer"
                        @click="DeleteData(data.item)"
                      />
                      <!-- {{ data.item.branch ? data.item.branch: '-' }} -->
                    </template>
                  </b-table>
                </div>
              </b-modal>
              <b-modal
                id="modal-receive-add-amend"
                :title="$t('addBankAccount')"
                :ok-title="$t('key-124')"
                :cancel-title="$t('cancel')"
                @ok.prevent="addNewbank()"
              >
                <validation-observer ref="addNewbank">
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label-for="h-name_bank"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          <span class="text-danger"> *</span> {{ $t("key-55") }}
                        </template>
                        <!-- <validation-provider
                          #default="{ errors }"
                          name="Bank"
                          rules="required"
                        > -->
                        <!-- <b-form-input
                            id="h-name_bank"
                            v-model="name_bank"
                            :state="errors.length > 0 ? false:null"
                          /> -->
                        <v-select
                          v-model="bank_obj"
                          class="style-chooser"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          :options="itemBankList"
                        >
                          <template #option="{ name, path_photo }">
                            <img
                              :src="`${path_photo}`"
                              :alt="name"
                              height="30"
                              class="mr-50 rounded-sm"
                            >
                            <span>{{ name }}</span>
                          </template>

                          <template #selected-option="{ name, path_photo }">
                            <img
                              :src="`${path_photo}`"
                              :alt="name"
                              height="30"
                              class="mr-50"
                            >
                            <span>{{ name }}</span>
                          </template>
                        </v-select>
                        <!-- <small class="text-danger">{{ errors[0] ? $t('requiredField')+'!':'' }}</small> -->
                        <!-- </validation-provider> -->
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-for="h-addresstex"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          <span class="text-danger"> *</span>
                          {{ $t("accountName") }}
                        </template>
                        <validation-provider
                          #default="{ errors }"
                          name="name_account"
                          rules="required"
                        >
                          <b-form-input
                            id="h-name_bank"
                            v-model="name_account"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{
                            errors[0] ? $t("requiredField") + "!" : ""
                          }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-for="h-number_account"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          <span class="text-danger"> *</span>
                          {{ $t("accountNumber") }}
                        </template>

                        <validation-provider
                          #default="{ errors }"
                          name="number_account"
                          rules="required"
                        >
                          <b-form-input
                            id="h-number_account"
                            v-model="number_account"
                            type="number"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{
                            errors[0] ? $t("requiredField") + "!" : ""
                          }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- <b-col md="12">
                      <b-form-group
                        label-for="h-branch"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t('branch') }}
                        </template>
                        <b-form-input
                          id="h-branch"
                          v-model="branch"
                        />
                      </b-form-group>
                    </b-col> -->
                  </b-row>
                </validation-observer>
              </b-modal>
            </div>
            </b-overlay>
          </div>
        </div>
      </div>
    </validation-observer>

    <b-modal
      id="modal-verifyBank"
      cancel-variant="outline-secondary"
      :ok-title="$t('key-124')"
      :cancel-title="$t('cancel')"
      centered
      :title="$t('key-207')"
      @ok.prevent="SubmitVerifyBank()"
    >

      <div class="w-100 h-100">
        <div class="d-flex justify-content-center flex-wrap">
          <img
            src="/Bank2.png"
            width="100%"
          >

          <img
            src="/Bank1.png"
            width="100%"
          >
          <h3 class="font-weight-bold text-danger text-center">
            *ชื่อบัญชี และบัตรประชาชน ต้องตรงกันเท่านั้น
          </h3>
        </div>

        <div class="d-flex justify-content-center align-items-center">
          <input
            ref="fileInputBank"
            type="file"
            accept="image/*"
            style="display: none"
            @change="fileImageBank"
          >
          <input
            ref="cameraInputBank"
            type="file"
            accept="image/*"
            capture="camera"
            style="display: none"
            @change="fileImageBank"
          >
          <b-button
            style="width: auto; height: 100px"
            variant="relief-primary"
            class="mr-1"
            @click="openFileInputBank"
          >
            <feather-icon
              icon="FilePlusIcon"
              class="mb-1"
              size="30"
            /><br><div style="width: 100px;">
              {{ $t('key-208') }}
            </div>
          </b-button>
          {{ $t("or") }}
          <b-button
            style="width: auto; height: 100px"
            variant="relief-success"
            class="ml-1"
            @click="openCameraBank"
          >
            <feather-icon
              icon="CameraIcon"
              class="mb-1"
              size="30"
            /><br>
            <div style="width: 100px;">
              {{ $t('key-209') }}
            </div>
          </b-button>
        </div>

        <div class="mt-2 d-flex justify-content-center">
          <span
            v-if="imageOrBank"
            class="preview-image-containerv2 mr-2 d-flex justify-content"
          >
            <img
              :src="imageOrBank"
              alt="Preview"
              class=""
              @click="showPicture(imageOrVerify)"
            >
            <div class="preview-image-overlays p-0">
              <i
                class="fas fa-times-circle cursor-pointer"
                @click="deleteImageorderBank()"
              />
            </div>
          </span>
        </div>
      </div>
    </b-modal>

    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BButton,
  BFormFile,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormRadio,
  BTable,
  // BCard,
  BFormCheckbox,
  BBadge,
  BOverlay,
  // BFormRadioGroup,
} from 'bootstrap-vue'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import ThaiLocale from 'flatpickr/dist/l10n/th'
import vSelect from 'vue-select'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'
import SideBar from '../component/SideBar.vue'
import moment from 'moment-timezone'
export default {
  name: 'LineNotify',
  components: {
    ValidationObserver,
    ValidationProvider,
    SideBar,
    // ThisHeader,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BButton,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BFormRadio,
    flatPickr,
    BTable,
    // BCard,
    BFormCheckbox,
    vSelect,
    BBadge,
    // BFormRadioGroup,
    VueImageLightboxCarousel,
    BOverlay
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      images: [],
      showLightbox: false,
      required,
      selected: null,
      date: null,
      dateDefault: null,
      timePicker: null,
      dateNtim: null,
      multiDate: null,
      rangeDate: null,
      humanDate: null,
      disableDate: null,
      inlineDate: null,
      remark: null,
      Bank: [],
      price: null,
      Banklist: [],
      flatpickrConfig: {
        dateFormat: 'Y-m-d H:i',
        enableTime: true,
        time_24hr: true,
        onOpen: function(selectedDates, dateStr, instance) {
          instance.setDate(moment().toDate());
        }
        // defaultDate: moment().toDate() 
      },
      imageOr: null,
      imageOrVerify: null,
      bankcus: [],
      itemBank: [],
      itemBankselect: [],
      name_account: null,
      name_bank: null,
      number_account: null,
      bank_obj: null,
      branch: null,
      checkeditem: null,
      id: null,
      itemsbank: null,
      itemsbankVerify: null,
      imageOrBank: null,
      itemBankList: [],
      items: null,
      isDisabled: false, 
      showOver: false,
    }
  },
  computed: {
    fieldsbank() {
      return [
        {
          key: 'set',
          label: '',
          thStyle: { width: '5%' },
          thClass: 'text-center',
        },
        {
          key: 'name_bank',
          label: this.$t('key-55'),
          thStyle: { width: '15%' },
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'name_account',
          label: this.$t('accountName'),
          thStyle: { width: '15%' },
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'number_account',
          label: this.$t('accountNumber'),
          thStyle: { width: '15%' },
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        // {
        //   key: 'branch', label: this.$t('branch'), thStyle: { width: '15%' }, tdClass: 'text-center', thClass: 'text-center',
        // },
        {
          key: 'actions',
          label: this.$t('manage'),
          thStyle: { width: '10%' },
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.getBanklist()
    this.getBank()
    this.getSelect()
    this.getData()
  },
  methods: {

    verifyBank(data) {
      if (data) {
        this.itemsbankVerify = data
      } else {
        this.itemsbankVerify = null
      }
      this.$bvModal.show('modal-verifyBank')
    },
    async getData() {
      this.showOver = true
      try {
        const { data: res } = await this.$http.get('/Profilecustomer/show')
        this.items = res
        this.showOver = false
      } catch (e) {
        console.log(e)
        this.showOver = false
      }
    },
    SubmitVerifyBank() {
      if (this.items.verify_status !== 2) {
        this.SwalError(this.$t('key-210'))
      } else if (!this.imageOrBank) {
        this.SwalError(this.$t('key-212'))
      } else {
        const obj = {
          id: this.itemsbankVerify._id,
          status: 1,
          file_bank: this.imageOrBank,
        }
        this.$http.post('/Refill/StoreBank', obj)
          .then(() => {
            this.getBank()
            this.Success(this.$t('1-2'))
            this.$bvModal.hide('modal-verifyBank')
            this.imageOrBank = null
          })
          .catch(err => {
            console.log(err)
            this.SwalError(err)
          })
      }
    },
    fileImage(event) {
      if (event) {
        this.file_cus = event.target.files

        // this.fileimg_th = null
        const reader = new FileReader()
        const filepath = this.file_cus[0]
        if (!filepath) {
          this.imageOr = null
        } else {
          reader.onload = e => {
            // console.log(e.target.result)
            // console.log(e.target.result)
            this.imageOr = e.target.result
            // eslint-disable-next-line no-undef

            // console.log(this.filecusimg)
            // e.target.result
            // console.log(this.namemap)
          }
          reader.readAsDataURL(filepath)
        }
      }
    },
    fileImageBank(event) {
      if (event) {
        this.file_cusBank = event.target.files

        // this.fileimg_th = null
        const reader = new FileReader()
        const filepath = this.file_cusBank[0]
        if (!filepath) {
          this.imageOrBank = null
        } else {
          reader.onload = e => {
            // console.log(e.target.result)
            // console.log(e.target.result)
            this.imageOrBank = e.target.result
            // eslint-disable-next-line no-undef

            // console.log(this.filecusimg)
            // e.target.result
            // console.log(this.namemap)
          }
          reader.readAsDataURL(filepath)
        }
      }
    },
    openFileInputBank() {
      this.$refs.fileInputBank.click()
    },
    openCameraBank() {
      this.$refs.cameraInputBank.click()
    },
    deleteImageorderBank() {
      this.file_cusBank = null
      this.imageOrBank = null
    },

    async getSelect() {
      try {
        const { data: res } = await this.$http.get('/bank')
        this.itemBankList = res.map(ele => ({
          id: ele.id,
          bankcode: ele.bankcode,
          name: ele.name,
          path_photo: `/bankIcon/${ele.path_photo}`,
        }))
      } catch (e) {
        console.log(e)
      }
    },
    async getBanklist() {
      try {
        const { data: res } = await this.$http.get('/Refill/getBank')
        this.Bank = res
      } catch (err) {
        console.log(err)
      }
    },
    Submit() {
      this.isDisabled = true
      this.showOver = true
      // console.log(this.selected)
      // console.log(this.itemsbank)
      if (!this.selected) {
        this.isDisabled = false
        this.showOver = false
        return this.SwalError('เลือกเลขบัญชีที่ต้องการโอนเข้า')
      } else if (!this.itemsbank) {
        this.isDisabled = false
        this.showOver = false
        return this.SwalError('เลือกบัญชีโอนเงิน')
      } else if (!this.price || this.price <= 0) {
        this.showOver = false
        this.isDisabled = false
        return this.SwalError('กรอกจำนวนที่ต้องการโอน')
      } else if (!this.dateNtim) {
        this.showOver = false
        this.isDisabled = false
        return this.SwalError('กรอกวันที่ทำรายการ')
      } else if (!this.imageOr) {
        this.isDisabled = false
        this.showOver = false
        return this.SwalError('กรุณาแนบหลักฐานการโอน')
      } else {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            const obj = {
              accountbank: this.selected,
              itemsbank: this.itemsbank,
              amount: this.price,
              file_date: this.dateNtim,
              fileimg: this.imageOr,
              remark: this.remark,
            }
            this.$http
              .post('Refill/StoreRefill', obj)
              .then(response => {
                if (response.data.status) {
                  this.Success('บันทึกข้อมูลเรียบร้อย')
                  this.$router.push({ name: 'customer-refill' })
                  this.showOver = false
                }
              })
              .catch(err => {
                console.log(err)
                this.SwalError(err)
                this.isDisabled = false
                this.showOver = false
              })
          }
        })
      }
    },
    async getBank() {
      try {
        const { data: res } = await this.$http.get('/Refill/getBankcus')
        this.itemBank = res
      } catch (err) {
        console.log(err)
      }
    },
    addBank() {
      this.itemsbank = this.checkeditem
    },
    DeleteData(data) {
      this.$bvModal
        .msgBoxConfirm('ลบข้อมูลบัญชีธนาคารนี้?', {
          title: 'ยืนยันการลบข้อมูล',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(result => {
          if (result) {
            const obj = {
              // eslint-disable-next-line no-underscore-dangle
              id: data._id,
            }
            this.$http
              .post('/Refill/DeleteBank', obj)
              .then(() => {
                this.getBank()
                this.Success('ลบบัญชีธนาคารเรียบร้อย')
              })
              .catch(err => {
                console.log(err)
                this.SwalError(err)
              })
          }
        })
    },
    addNewbank() {
      this.$refs.addNewbank.validate().then(success => {
        if (success) {
          const obj = {
            id: this.id,
            name_bank: this.bank_obj.name,
            bank_code: this.bank_obj.bankcode,
            name_account: this.name_account,
            number_account: this.number_account,
            branch: this.branch,
          }
          this.$http
            .post('/Refill/StoreBank', obj)
            .then(() => {
              this.$bvModal.hide('modal-receive-add-amend')
              this.Success('บันทึกข้อมูลเรียบร้อย')
              this.getBank()
            })
            .catch(err => {
              this.SwalError(err.response.data.message)
              console.log(err)
            })
        }
      })
    },

    EditType(data) {
      if (data) {
        // eslint-disable-next-line no-underscore-dangle
        this.id = data._id
        this.name_bank = data.name_bank
        this.name_account = data.name_account
        this.number_account = data.number_account
        this.branch = data.branch
        const FindBank = this.itemBankList.find(
          ele => ele.bankcode === data.bank_code,
        )
        this.bank_obj = FindBank
      } else {
        this.id = null
        this.name_bank = null
        this.name_account = null
        this.number_account = null
        this.branch = null
        this.bank_obj = null
      }
      this.$bvModal.show('modal-receive-add-amend')
    },
    deleteImageorder() {
      this.file_cus = null
      this.imageOr = null
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: picture }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

// จำกัดความสูง select เมื่อ dropdown
.style-chooser .vs__dropdown-menu {
  max-height: 180px;
  overflow-y: auto;
}

</style>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.hide-bullet {
  list-style-type: none;
}

.bg-custom {
  background-color: rgb(54, 54, 54, 0.2);
  border-radius: 10px;
}

.radio-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.radio-image {
  width: 50px;
  height: 50px;
}

/* การเพิ่ม padding รอบ ๆ ปุ่มเลือก */
.custom-radio .custom-control-input ~ .custom-control-label::before {
  padding: 10px;
}
</style>
